import React, {Component} from "react"
import Layout from '../components/layout'
import SEO from '../components/seo'
import {graphql} from "gatsby"
import {Link} from 'gatsby'
// import SpecializationBox from '../components/specialization-box'
import AnimateComponent from '../components/animate-component'

class MediasTemplate extends Component {

    render() {

        const posts = this.props.data.allWordpressWpMedias.edges;
        console.log(posts)

        return (

            <Layout>
                <SEO title="Aktualności prawnicze | Świecki Kancelaria"/>
                <AnimateComponent styleName="blog-page" tag='section'>
                    <div className="blog-page__container">
                                    <header className="specialization-page__header wow zoomIn">
                            <div className="specialization-page__title-container">
                                <h1>
                                    Media
                                </h1>
                                <h2>
                                   Wybrane publikacje
                                </h2>
                            </div>
                            <div className="specialization-page__text-block">
                                <p>
                                Nasze analizy i komentarze w mediach.
                                </p>

                            </div>
                        </header>

                        <div className="blog-page__items">
                                {posts.map(({node}) => (

                                <div className='blog-page__item item wow fadeInUp'key={node.id}>

                                <Link className="item__link" to={ node.slug}>
                                    <div className="item__image-wrapper item__image-wrapper--full">
                                    {node.featured_media && node.featured_media.localFile && node.featured_media.localFile.publicURL && <img
                                        type="icon"
                                        src={node.featured_media.localFile.publicURL}
                                        className="item__image"
                                        alt=''/>
                                    }

                                    </div>
                                    <h3  dangerouslySetInnerHTML={{ __html: node.title }} />

                                    <p  dangerouslySetInnerHTML={{ __html: node.excerpt }} />

                                    <p class="item__link-p">Czytaj więcej</p>
                                </Link>
                            </div>))}
                        </div>
                    </div>
                </AnimateComponent>
            </Layout>
        )
    }
}

export default MediasTemplate

export const query = graphql `
  query{
    allWordpressWpMedias( sort: {fields: date, order: DESC} ) {
        edges {
          node {
            title
            content
            slug
            id
            featured_media{
                localFile {
                    publicURL
                }
            }
          }

        }
      }
  }
`
